import DatePicker from 'react-datepicker';

import toDollars from '../../utils/toDollars';

import {
  ADJUSTMENTS,
  CITY,
  DAD,
  DATE,
  DELETE,
  DUE_BY,
  HOSTING,
  INVOICE,
  MOM,
  NOTE,
  PARENTS,
  PAYABLE_TO,
  PAYABLE_TO_CITY,
  PAYABLE_TO_PHONE,
  PAYABLE_TO_STREET_ADDRESS,
  PRICE,
  QUANTITY,
  RATE,
  SUBMITTED_ON,
  THANK_YOU,
  TOTAL,
  TYPE,
  VISITS,
  X
} from '../../constants';

import '../../dashboard.css';

const PARENT_TYPE = {
  MOM,
  DAD,
  PARENTS
};

const BOOKING_TYPE = {
  HOSTING,
  VISITS
};

const today = new Date();
const tomorrow = new Date(today);

tomorrow.setDate(tomorrow.getDate() + 1);

export default ({
  children,
  customer,
  parentType,
  bookingType,
  dueDate,
  invoiceNumber,
  invoiceLines,
  comment,
  isEditable = false,
  adjustments,
  paid,

  Editor: {
    pendingParentType,
    pendingBookingType,
    pendingDueDate,
    pendingLine,
    pendingDate,
    pendingQuantity,
    pendingPrice,
    pendingInvoiceNumber,
    onClickLine = () => {},
    onClickUpdateLine = () => {},
    onClickRemoveLine = () => {},
    onClickDueDate = () => {},
    onClickBookingType = () => {},
    onClickParentType = () => {},
    onClickInvoiceNumber = () => {},
    onChangePendingInvoiceNumber = () => {},
    onChangePendingDueDate = () => {},
    onChangePendingDate = () => {},
    onChangePendingQuantity = () => {},
    onChangeDueDate = () => {},
    onChangeBookingType = () => {},
    onChangeParentType = () => {},
    onChangeInvoiceNumber = () => {}
  } = {}
}) => {
  const { ownerName, catName, address } = customer;

  const addressParts = address.split(CITY);

  const total = invoiceLines.length
    ? invoiceLines
        .map(({ quantity, rate, price }) => (rate ? rate * quantity : price))
        .reduce((a, b) => +a + +b)
    : 0;

  return (
    <>
      <div className={`invoice${paid ? ' paid' : ''}`}>
        <h2>{PAYABLE_TO}</h2>
        <address>
          <p>{PAYABLE_TO_STREET_ADDRESS}</p>
          <p>{PAYABLE_TO_CITY}</p>
        </address>
        <p>{PAYABLE_TO_PHONE}</p>
        <h1>{paid ? THANK_YOU : INVOICE}</h1>
        <h3>
          {SUBMITTED_ON} {today.toLocaleDateString()}
        </h3>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
          <div style={{ width: '33%' }}>
            <h3>{INVOICE} for</h3>
            <p>{ownerName}</p>
            {isEditable && pendingParentType ? (
              <div style={{ display: 'flex' }}>
                <p>{catName}'s&nbsp;</p>
                <select
                  selected={isEditable ? pendingParentType : null}
                  onChange={isEditable ? onChangeParentType : null}
                  defaultValue={parentType}
                >
                  {Object.values(PARENT_TYPE).map(type => (
                    <option key={type}>{type}</option>
                  ))}
                </select>
              </div>
            ) : (
              <p onClick={isEditable ? onClickParentType : null}>
                {catName}'s {parentType}
              </p>
            )}
            <address>
              <p>{addressParts[0]}</p>
              <p>
                {CITY}
                {addressParts[1] || ', CA'}
              </p>
            </address>
          </div>
          <div style={{ width: '33%' }}>
            <h3>Sitter</h3>
            <p>{PAYABLE_TO}</p>
            <h3>{TYPE}</h3>
            {isEditable && pendingBookingType ? (
              <div style={{ display: 'flex' }}>
                <select
                  selected={isEditable ? pendingBookingType : null}
                  onChange={isEditable ? onChangeBookingType : null}
                  defaultValue={bookingType}
                >
                  {Object.values(BOOKING_TYPE).map(type => (
                    <option key={type}>{type}</option>
                  ))}
                </select>
              </div>
            ) : (
              <p onClick={isEditable ? onClickBookingType : null}>
                {bookingType}
              </p>
            )}
          </div>
          <div style={{ width: '33%' }}>
            <h3>{INVOICE} #</h3>
            {isEditable && pendingInvoiceNumber ? (
              <span style={{ display: 'flex' }}>
                <input
                  type="numeric"
                  value={isEditable ? pendingInvoiceNumber : 1}
                  onChange={
                    isEditable
                      ? ({ target: { value } }) =>
                          onChangePendingInvoiceNumber(value)
                      : null
                  }
                  style={{
                    display: 'block',
                    width: '100%',
                    margin: '1rem auto',
                    padding: '.5rem'
                  }}
                />
                <button
                  style={{
                    display: 'block',
                    margin: 'auto',
                    padding: '.5rem',
                    alignSelf: 'flex-end',
                    background: 'none',
                    color: 'green',
                    fontSize: '1.5em'
                  }}
                  onClick={isEditable ? onChangeInvoiceNumber : null}
                >
                  ✓
                </button>
              </span>
            ) : (
              <p onClick={isEditable ? onClickInvoiceNumber : null}>
                {invoiceNumber}
              </p>
            )}
            <h3>{DUE_BY}</h3>
            {isEditable && pendingDueDate ? (
              <div style={{ display: 'flex' }}>
                <DatePicker
                  selected={isEditable ? pendingDueDate : today}
                  onChange={isEditable ? onChangePendingDueDate : null}
                  style={{ width: '30%' }}
                />
                <button
                  style={{
                    display: 'block',
                    margin: 'auto',
                    padding: '.5rem',
                    alignSelf: 'flex-end',
                    background: 'none',
                    color: 'green',
                    fontSize: '1.5em'
                  }}
                  onClick={isEditable ? onChangeDueDate : null}
                >
                  ✓
                </button>
              </div>
            ) : (
              <p onClick={isEditable ? onClickDueDate : null}>
                {dueDate.toLocaleDateString()}
              </p>
            )}
          </div>
        </div>

        <div className="invoice-table-heading">
          <h3 style={{ width: '30%' }}>{DATE}</h3>
          <h3 style={{ width: '30%' }}>{QUANTITY}</h3>
          <h3 style={{ width: '30%' }}>{isEditable ? PRICE : RATE}</h3>
          <h3 style={isEditable ? { width: '10%', textAlign: 'right' } : {}}>
            {isEditable ? DELETE : TOTAL}
          </h3>
        </div>
        <ul className="invoice-lines">
          {invoiceLines
            .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
            .map(({ id, date, quantity, price, rate }, index) =>
              isEditable && index === pendingLine ? (
                <li
                  key={`pending-invoice-line-${id}`}
                  className="invoice-line editing"
                >
                  <span style={{ width: 'calc(30% - 1.5rem)' }}>
                    <DatePicker
                      selected={isEditable ? pendingDate : today}
                      onChange={isEditable ? onChangePendingDate : null}
                      style={{ width: '100%' }}
                    />
                  </span>
                  <span style={{ width: 'calc(30% - .5rem)' }}>
                    <input
                      type="numeric"
                      value={isEditable ? pendingQuantity : 0}
                      onChange={
                        isEditable
                          ? ({ target: { value } }) =>
                              onChangePendingQuantity(value)
                          : null
                      }
                      style={{
                        display: 'block',
                        width: '100%',
                        margin: '1rem auto',
                        padding: '.5rem'
                      }}
                    />
                  </span>
                  <span style={{ width: 'calc(30% - 1rem)' }}>
                    <input
                      disabled
                      value={isEditable ? toDollars(pendingPrice) : 0}
                      style={{
                        display: 'block',
                        width: '100%',
                        margin: '1rem auto',
                        padding: '.5rem'
                      }}
                    />
                  </span>
                  <button
                    style={{
                      display: 'block',
                      margin: 'auto 0 auto auto',
                      padding: '.5rem',
                      alignSelf: 'flex-end',
                      background: 'none',
                      color: 'green',
                      fontSize: '1.5em'
                    }}
                    onClick={isEditable ? onClickUpdateLine(index) : null}
                  >
                    ✓
                  </button>
                </li>
              ) : (
                <li
                  key={`invoice-line-${id}`}
                  className="invoice-line"
                  onClick={isEditable ? onClickLine(index) : null}
                >
                  <span style={{ width: '30%' }}>{date}</span>
                  <span style={{ width: '30%' }}>{quantity}</span>
                  <span style={{ width: '30%' }}>
                    {toDollars(isEditable ? price : rate)}
                  </span>
                  {isEditable ? (
                    <button
                      className="delete-button"
                      onClick={isEditable ? onClickRemoveLine(index) : null}
                    >
                      {X}
                    </button>
                  ) : (
                    toDollars(quantity * rate)
                  )}
                </li>
              )
            )}
          {adjustments > 0 && (
            <li className="invoice-line adjustments">
              <span style={{ width: '30%' }}>{ADJUSTMENTS}</span>
              <span style={{ width: '30%' }}>{invoiceLines.length}</span>
              <span style={{ width: '30%' }}>{toDollars(adjustments)}</span>
              <span style={{ width: '10%' }}>
                {isEditable && `${TOTAL}: `}
                {toDollars(adjustments * invoiceLines.length)}
              </span>
            </li>
          )}
        </ul>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <h3
            style={{
              fontSize: '1.75em',
              color: paid ? '#32be3f' : 'auto'
            }}
          >
            {TOTAL}
            {paid ? ' Paid' : ''}: {toDollars(total)}
          </h3>
        </div>
        {children}
        {!isEditable && (
          <>
            <h3>{NOTE}</h3>
            <p style={{ lineHeight: '2.2em' }}>{comment}</p>
          </>
        )}
      </div>
    </>
  );
};
