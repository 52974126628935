/**
 * Exclude specific dates here.
 * These will be disabled in the datepicker
 * on the lead form.
 */

export default [
  new Date('May 28, 2025'),
  new Date('May 29, 2025'),
  new Date('May 30, 2025'),
  new Date('June 1, 2025'),
  new Date('June 2, 2025'),
  new Date('June 3, 2025'),
  new Date('June 4, 2025'),
  new Date('June 5, 2025'),
  new Date('June 6, 2025'),
  new Date('June 7, 2025'),
  new Date('June 8, 2025'),
  new Date('June 9, 2025'),
  new Date('June 10, 2025'),
  new Date('June 11, 2025'),
  new Date('June 12, 2025'),
  new Date('June 13, 2025'),
  new Date('June 14, 2025'),
  new Date('June 15, 2025')
];
